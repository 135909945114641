import {gql} from 'graphql-request'

export const LINK_FRAGMENT = gql`
    fragment LinkFragment on Link {
        text
        url
        externalUrl
        openLinkInNewTab
        iconName
        theme {
            backgroundColor
            color
        }
        sys {
            id
        }
        type
        actionType
        isExternal
        __typename
    }
`

export const LINK_FRAGMENT_FOR_LARGE_QUERY = gql`
    fragment LinkFragmentForLargeQueryFragment on Link {
        text
        url
        externalUrl
        openLinkInNewTab
        iconName
        theme {
            backgroundColor
            color
        }
        type
        actionType
        isExternal
        sys {
            id
        }
    }
`
