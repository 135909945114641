import PropTypes from 'prop-types'
import React, {useCallback, useEffect, useState} from 'react'

import {
    List,
    SimpleGrid,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {styleConfigChakraKeys} from '../../constants'
import MegaLink from './mega-link'

export default function MegaNavLinks({items, onClose}) {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.megaLink)
    const [dispColumntwo, setDisplayColumnTwo] = useState(true)
    const [dispColumnthree, setDisplayColumnThree] = useState(true)
    const [secondCategoryColumn, setSecondCategoryColumn] = useState([])
    const [thirdCategoryColumn, setThirdCategoryColumn] = useState([])
    const [activeLinkId, setActiveLinkId] = useState('')
    const [activeLinkId2, setActiveLinkId2] = useState('')
    const [activeLinkId3, setActiveLinkId3] = useState('')

    const handleColumn1LinkClick = useCallback(
        (data) => {
            setDisplayColumnTwo(false)
            setSecondCategoryColumn(JSON.parse(JSON.stringify(data)))
            setThirdCategoryColumn([])
        },
        [setSecondCategoryColumn, setThirdCategoryColumn]
    )

    const handleColumn2LinkClick = useCallback(
        (data) => {
            setDisplayColumnThree(false)
            setThirdCategoryColumn(JSON.parse(JSON.stringify([])))
            setTimeout(() => {
                setThirdCategoryColumn(JSON.parse(JSON.stringify(data)))
            }, 100)
        },
        [setThirdCategoryColumn]
    )

    const handleClearActiveLinkId2 = useCallback(() => {
        setActiveLinkId2('')
    }, [])

    const handleClearActiveLinkId3 = useCallback(() => {
        setActiveLinkId3('')
    }, [])

    useEffect(() => {
        setDisplayColumnTwo(true)
    }, [secondCategoryColumn, dispColumntwo])

    useEffect(() => {
        setDisplayColumnThree(true)
    }, [thirdCategoryColumn, dispColumnthree])

    return (
        <SimpleGrid
            spacing="21px"
            justifyContent="left"
            width="53%"
            gridTemplateColumns="193px minmax(220px, 236px) minmax(220px, 236px)"
            paddingBottom="27px"
            minHeight="251px"
        >
            <List {...styles.listMenuColumn}>
                {items?.map((item, iValue) => {
                    const isLink = item.__typename === 'Link' ? true : false
                    return (
                        <MegaLink
                            item={item}
                            isLink={isLink}
                            onLinkClick={onClose}
                            setNextCategoryColumn={handleColumn1LinkClick}
                            iValue={iValue}
                            key={item?.sys?.id}
                            activeLinkId={activeLinkId}
                            setActiveLinkId={setActiveLinkId}
                        />
                    )
                })}
            </List>
            {secondCategoryColumn?.length > 0 && (
                <List
                    {...styles.listMenuColumn}
                    display={dispColumntwo ? 'block' : 'none'}
                >
                    {secondCategoryColumn?.map((item, iValue) => {
                        const isLink = item.__typename === 'Link' ? true : false

                        return (
                            <MegaLink
                                item={item}
                                isLink={isLink}
                                onLinkClick={onClose}
                                setNextCategoryColumn={handleColumn2LinkClick}
                                iValue={iValue}
                                key={item?.sys?.id}
                                activeLinkId={activeLinkId2}
                                setActiveLinkId={handleClearActiveLinkId2}
                            />
                        )
                    })}
                </List>
            )}

            {thirdCategoryColumn?.length > 0 && (
                <List
                    {...styles.listMenuColumn}
                    display={dispColumnthree ? 'block' : 'none'}
                >
                    {thirdCategoryColumn?.map((item, iValue) => {
                        const isLink = item.__typename === 'Link' ? true : false

                        return (
                            <MegaLink
                                item={item}
                                isLink={isLink}
                                onLinkClick={onClose}
                                iValue={iValue}
                                key={item?.sys?.id}
                                activeLinkId={activeLinkId3}
                                setActiveLinkId={handleClearActiveLinkId3}
                            />
                        )
                    })}
                </List>
            )}
        </SimpleGrid>
    )
}

MegaNavLinks.propTypes = {
    items: PropTypes.array,
    onClose: PropTypes.func
}
