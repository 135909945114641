export const headerData = {
    headerCollection: {
        items: [
            {
                leftCollection: {
                    items: [
                        {
                            sys: {
                                id: '3Ar43vJ11kCyxBFdS4kK6dU'
                            },
                            __typename: 'List',
                            name: null,
                            heading: 'Shop',
                            itemsCollection: {
                                items: [
                                    {
                                        sys: {
                                            id: '1q4g1YgYZOVrODuFPAP4bp3'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Make Up',
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: '25gs1cNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Face',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbD1m2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Primer',
                                                                url: '/category/fa_primer',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC15CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Foundation',
                                                                url: '/category/foundation',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs11DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tinted Moisturizer',
                                                                url: '/category/tint_moist',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WOD1Vs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Concealer',
                                                                url: '/category/concealer',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1W1ODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Powder',
                                                                url: '/category/powder',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '11WODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Setting Spray & Powder',
                                                                url: '/category/setting_sp',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzVhvbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Blush',
                                                                url: '/category/blush',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODvVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Highlighter',
                                                                url: '/category/highlightr',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHdzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Bronzer',
                                                                url: '/category/bronzer',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzVshbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tools & Accessories',
                                                                url: '/category/fa_tools',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLfHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Face Applicator',
                                                                url: '/category/fa_applic',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODjVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Face',
                                                                url: '/category/mkp_total_face',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Eyes',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Brows',
                                                                url: '/category/eye_brow',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFCa5CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eye Primer',
                                                                url: '/category/eye_primer',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODaVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eye Shadow',
                                                                url: '/category/eye_shadow',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WnODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eye Liner',
                                                                url: '/category/eye_liner',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WnODVs1bjkDLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Mascara',
                                                                url: '/category/mascara',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1nWODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'False Lashes',
                                                                url: '/search?q=lash',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WcODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tools & Accessoires',
                                                                url: '/category/eye_tools',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVxs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Applicator',
                                                                url: '/category/eye_applic',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLzHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Eyes',
                                                                url: '/category/mkp_total_eye',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavPc4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Lips',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2Zjkrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Lip Primer',
                                                                url: '/category/lip_primer',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5CA7sdSfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Lip Colour',
                                                                url: '/category/lip_color',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DbvLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Lip Gloss',
                                                                url: '/category/lip_gloss',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLmxHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Lip Liner',
                                                                url: '/category/lip_liner',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DnxLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tools & Accessories',
                                                                url: '/category/lip_tools',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzVzzhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Lip Applicator',
                                                                url: '/category/lip_applic',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzVhbN7zzImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Lips',
                                                                url: '/category/mkp_total_lip',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavP4xlztprPLUzzhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Nails',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2Zrj9cxdUSaImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Nail Care',
                                                                url: '/category/nail_care',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5CA7SfFb41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Base & Top Coat',
                                                                url: '/category/nail_care',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVbnbs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Nail Colour',
                                                                url: '/category/clr_enamel',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            // {
                                                            //     sys: {
                                                            //         id: '1WODVs1DLnmnHzVhbN7ImBlIp'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Nail Tools & Accessories',
                                                            //     url: '/category/nail_tools',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: 'nailAll'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Nails',
                                                                url: '/category/mkp_total_nail',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavP4xlzviptprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Makeup Tools',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2krZrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Makeup Tools',
                                                                url: '/category/oth_face',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5CA7SfF4ss1rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Accessories',
                                                                url: '/category/all_other',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            // {
                                                            //     sys: {
                                                            //         id: '1WODVsss1DLHzVhbN7ImBlIp'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Applicators',
                                                            //     url: '/category/oth_applic',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: '1WaaODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tools & Accessories',
                                                                url: '/category/oth_tools',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'allMakeupTools'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Makeup Tools',
                                                                url: '/category/oth_tools',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscasNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Make Up Sets',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TnaAJbDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Makeup Sets',
                                                                url: '/category/set',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'allSets'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Makeup Sets',
                                                                url: '/category/mkp_total_sets',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'shopAllMakeup'
                                                    },
                                                    __typename: 'Link',
                                                    name: null,
                                                    text: 'All Make Up',
                                                    url: '/category/makeup',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: '3mKayh7v1eerJtsbBzl6J97'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Skincare',
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: '215agscNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Face',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6aTAJbDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Cleanser',
                                                                url: '/category/fa_clnsr',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFbnbC5CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Treatment',
                                                                url: '/category/fa_serum',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WzghODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eye',
                                                                url: '/category/eye_trtmnt',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WzghODVs1D000LHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Moisturizer',
                                                                url: '/category/fa_cream',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1rg1WODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eye & Face Mask',
                                                                url: '/category/s_mask',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1rg1WODVs1DLHzVhbN7ImBlIp00'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Face',
                                                                url: '/category/skin_total_face',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                // {
                                                //     sys: {
                                                //         id: '25gscvb3NnavP4xlztprPLUhG'
                                                //     },
                                                //     __typename: 'List',
                                                //     name: null,
                                                //     heading: 'Grooming',
                                                //     itemsCollection: {
                                                //         items: [
                                                //             {
                                                //                 sys: {
                                                //                     id: '6TAJshbbDm2Zrj9cxdUSImfse'
                                                //                 },
                                                //                 __typename:
                                                //                     'Link',
                                                //                 text: 'Grooming',
                                                //                 url: '/',
                                                //                 externalUrl:
                                                //                     null,
                                                //                 openLinkInNewTab: false,
                                                //                 iconName: null,
                                                //                 theme: null,
                                                //                 type: 'unstyled',
                                                //                 isExternal: false
                                                //             }
                                                //         ]
                                                //     }
                                                // },
                                                {
                                                    sys: {
                                                        id: '25gmljscNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Body Care',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAshJbDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Body Cleanser',
                                                                url: '/category/s_oth_body',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AqtyFC5CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Body Treatment',
                                                                url: '/category/bo_serum',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WOnDVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Body Moisturizer',
                                                                url: '/category/bo_crm_ltn',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '109WOnDVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Hand Soap',
                                                                url: '/category/hand_soap',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1nmWODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Deodorant & Antiperspirant',
                                                                url: '/category/s_deo',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1nmWODVs1DLHzVhbN7ImBlIpfhd'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Body Care All',
                                                                url: '/category/skin_total_body',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscvbvNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Spf-Suncare',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2bbZrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Face Spf',
                                                                url: '/category/fa_in_sun',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFCvbv5CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Body Spf',
                                                                url: '/category/bo_in_sun',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzVhbN7ImBlIp12'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'After Sun',
                                                                url: '/category/after_sun',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzVhbN7ImBlIp21'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Self-Tanner',
                                                                url: '/category/s-TANNER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzVhbN7IggmBlIp21'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Spf Suncare All',
                                                                url: '/category/skin_total_sun',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavP4xlztprbnbPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Skincare Tools',
                                                    itemsCollection: {
                                                        items: [
                                                            // {
                                                            //     sys: {
                                                            //         id: '6TAJbDm2Znmnrj9cxdUSImfse'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Face Tools',
                                                            //     url: '/',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            // {
                                                            //     sys: {
                                                            //         id: '1AFC5bvnCA7SfF41rVexinsxM'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Body Tools',
                                                            //     url: '/',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5bvnCA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Skincare Tools All',
                                                                url: '/category/skin_sets___kits',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavPkrs4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Skincare Sets',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDmbnv2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Skincare Sets',
                                                                url: '/category/sets_kits',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDmbnv2Zfrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Skincare Sets',
                                                                url: '/category/skin_sets___kits',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'shopAllSkincare'
                                                    },
                                                    __typename: 'Link',
                                                    name: null,
                                                    text: 'All Skincare',
                                                    url: '/category/skincare',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: 'PKQeaNzSfa5TaGNlZvg35'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Fragrances',
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: '25gscNnavP4xlztprPLUhGlhm'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Fragrances',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6fgvTAJbDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eau de Toilette',
                                                                url: '/category/edt',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFCl905CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eau de Parfum',
                                                                url: '/category/edp',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AbddFC5CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Parfum',
                                                                url: '/category/perfume',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVnbdbs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Cologne',
                                                                url: '/search?q=cologne',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVsbbfj1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Solid & Novel',
                                                                url: '/category/oth_juic',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVsbbfj1DLHggzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Fragrances',
                                                                url: '/category/frag_ancillaries',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'homeAndLifestyleCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Home & Lifestyle',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleCandles'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Candles',
                                                                url: '/category/candles',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleDiffusers'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Home Diffusers',
                                                                url: '/category/diffusers',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            // {
                                                            //     sys: {
                                                            //         id: 'homeAndLifestyleCarDiffuser'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Car Diffuser',
                                                            //     url: '/category/home_sets',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleRoomFresheners'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Room Fresheners & Accessories',
                                                                url: '/category/diffusers',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleRoomFresheners'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Home & Lifestyle',
                                                                url: '/category/frag_home_scents',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'bathAndBodyCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Bath & Body',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyBodyCleanser'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Body Cleanser',
                                                                url: '/category/f_oth_body',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyBodyLotion'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Body Lotion',
                                                                url: '/category/body_oil',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyBodyMist'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Body Mist',
                                                                url: '/category/body_spray',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyHairMist'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Hair Mist',
                                                                url: '/category/hair',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            // {
                                                            //     sys: {
                                                            //         id: 'bathAndBodyForTheBath'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'For the Bath',
                                                            //     url: '/category/buble_bath',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyDeodorant'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Deodorant',
                                                                url: '/category/f_deo',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            // {
                                                            //     sys: {
                                                            //         id: 'bathAndBodyGrooming'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Grooming',
                                                            //     url: '/category/shave',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodySoap'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Soap',
                                                                url: '/category/soap',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'allBathAndBody'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Bath & Body',
                                                                url: '/category/frag_total_juices',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'fragranceSetsCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Fragrance Sets',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'fragranceSets'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Fragrance Sets',
                                                                url: '/category/oth_sets',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'fragranceSetsAll'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Fragrance Sets',
                                                                url: '/category/frag_gift_sets',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'shopAllFragrance'
                                                    },
                                                    __typename: 'Link',
                                                    name: null,
                                                    text: 'All Fragrances',
                                                    url: '/category/fragrances',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: '2YHIActKx8AVKtY8mA4CcM'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Hair',
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: 'customerFacingCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Shampoo & Conditioner',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'customerFacingShampoo'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Shampoo',
                                                                url: '/category/daily_sham',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'customerFacingConditioner'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Conditioner',
                                                                url: '/category/daily_cond',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'hairCareAll'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Hair Care',
                                                                url: '/category/hair_care',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'treatmentCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Treatment',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'treatmentHairMask'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Hair Mask',
                                                                url: '/category/h_mask',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            // {
                                                            //     sys: {
                                                            //         id: 'treatmentHairColor'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Hair Color',
                                                            //     url: '/category/fa_color',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: 'treatmentScalpCare'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Scalp Care',
                                                                url: '/category/oth_care',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'stylingCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Styling',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'stylingHairStyling'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Hair Styling',
                                                                url: '/category/oth_styl',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'stylingHairStylingSpray'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Hair Styling Spray',
                                                                url: '/category/spray',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'stylingMousseFoam'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Mousse & Foam',
                                                                url: '/category/mousse',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            // {
                                                            //     sys: {
                                                            //         id: 'stylingDryShampoo'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Dry Shampoo',
                                                            //     url: '/category/dry_sham',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: 'AllHairStyling'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Hair Styling',
                                                                url: '/category/hair_styling',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'hairSetsCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Hair Sets',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'hairSets'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Hair Sets',
                                                                url: '/category/sets',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'allHairSets'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'All Hair Sets',
                                                                url: '/category/hair_sets',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'shopAllHair'
                                                    },
                                                    __typename: 'Link',
                                                    name: null,
                                                    text: 'All Hair',
                                                    url: '/category/hair',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: 'brands'
                                        },
                                        __typename: 'Link',
                                        name: null,
                                        text: 'Brands',
                                        url: '/pages/brands',
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'unstyled',
                                        isExternal: false
                                    },
                                    {
                                        sys: {
                                            id: '1W1ODVs1DLHzVhbN7Im909'
                                        },
                                        __typename: 'Link',
                                        name: null,
                                        text: 'Gift Sets',
                                        url: '/category/gift-sets',
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'unstyled',
                                        isExternal: false
                                    },
                                    {
                                        sys: {
                                            id: '1NaEeldvjCHUOVfJAIrnGo'
                                        },
                                        __typename: 'Link',
                                        name: null,
                                        text: 'Wellness (Coming Soon)',
                                        isDisabled: true,
                                        url: '#',
                                        externalUrl: null,
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'unstyled',
                                        isExternal: false
                                    },
                                    {
                                        sys: {
                                            id: '1bbNaEeldvjCHUOVfJAIrnGo'
                                        },
                                        __typename: 'Link',
                                        name: null,
                                        // heading: 'The Rest of it',
                                        text: 'The Rest of it',
                                        url: '/',
                                        type: 'unstyled',
                                        theme: null,
                                        isDisabled: true
                                    },
                                    {
                                        sys: {
                                            id: 'restOfItItems13'
                                        },
                                        __typename: 'Link',
                                        text: 'New in',
                                        isDisabled: true,
                                        url: '/',
                                        externalUrl: null,
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'unstyled',
                                        isExternal: false
                                    },
                                    {
                                        sys: {
                                            id: 'restOfItItems14'
                                        },
                                        __typename: 'Link',
                                        text: 'Best Sellers',
                                        isDisabled: true,
                                        url: '/',
                                        externalUrl: null,
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'unstyled',
                                        isExternal: false
                                    }
                                ]
                            }
                        },
                        {
                            sys: {
                                id: '5DLddapvHfrI9EVOxVDH0UEvents'
                            },
                            __typename: 'Link',
                            text: 'Events',
                            url: '/events',
                            externalUrl: null,
                            openLinkInNewTab: false,
                            iconName: null,
                            theme: null,
                            type: 'tertiary',
                            isExternal: false
                        },
                        {
                            sys: {
                                id: '5DLddapvHfrI9EVOxVDH0U'
                            },
                            __typename: 'List',
                            text: 'Realm',
                            heading: 'Realm',
                            url: '/pages/blogs',
                            externalUrl: null,
                            openLinkInNewTab: false,
                            iconName: null,
                            theme: null,
                            type: 'tertiary',
                            isExternal: false,
                            itemsCollection: {
                                items: [
                                    {
                                        sys: {
                                            id: 'articles'
                                        },
                                        __typename: 'Link',
                                        text: 'Articles',
                                        url: '/pages/blogs',
                                        externalUrl: null,
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'tertiary',
                                        isExternal: false
                                    },
                                    {
                                        sys: {
                                            id: 'ingredients'
                                        },
                                        __typename: 'Link',
                                        text: 'Ingredients Glossary',
                                        url: '/pages/ingredients',
                                        externalUrl: null,
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'tertiary',
                                        isExternal: false
                                    }
                                ]
                            }
                        },
                        {
                            sys: {
                                id: 'aboutLinkHeader'
                            },
                            __typename: 'List',
                            heading: 'About',
                            text: 'About',
                            url: '/pages/about',
                            externalUrl: null,
                            openLinkInNewTab: false,
                            iconName: null,
                            theme: null,
                            type: 'tertiary',
                            isExternal: false,
                            itemsCollection: {
                                items: [
                                    {
                                        sys: {
                                            id: 'about'
                                        },
                                        __typename: 'Link',
                                        text: 'About Rennaï',
                                        url: '/pages/about',
                                        externalUrl: null,
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'tertiary',
                                        isExternal: false
                                    },
                                    {
                                        sys: {
                                            id: 'careers'
                                        },
                                        __typename: 'Link',
                                        text: 'Careers @ Rennaï',
                                        url: '/pages/careers',
                                        externalUrl: null,
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'tertiary',
                                        isExternal: false
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    }
}
