import React from 'react'
import {useLocation} from 'react-router-dom'
import Footer from '.'
// import {Constants} from '../../pages/about/utils/constants'

const FooterWrapper = () => {
    // const {footer: footerData} = Constants
    const location = useLocation()
    // if (
    //     location.pathname.includes('/pages/about') ||
    //     location.pathname.includes('/pages/career')
    // ) {
    //     return (
    //         <AboutFooter
    //             links={footerData?.links || []}
    //             showLogo={footerData?.showLogo}
    //             social={footerData?.social}
    //             footerGradient={true}
    //         />
    //     )
    // }

    if (location.pathname.includes('/store/registration')) {
        return <></>
    }
    return <Footer />
}

export default FooterWrapper
