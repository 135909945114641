export const staticPagesSlugs = ['legals']

export const pagePositionMappings = [
    'termsOfUse',
    'termsOfPurchase',
    'backInStockNotification',
    'privacyPolicy',
    'cookiePolicy',
    'membershipTerms'
]
