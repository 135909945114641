import {colors} from '../../../constants'

export default {
    baseStyle: {
        box: {
            display: 'flex',
            flexDirection: {base: 'column', lg: 'row'},
            color: 'black',
            backgroundColor: colors.grayBgColor
        },
        contentBox: {
            flexDirection: 'column',
            width: '100%',
            margin: {
                base: 'auto 38px 44px 38px',
                lg: '142px auto 48px 60px'
            },
            pl: {base: '20px', lg: '80px'},
            pr: {base: '20px', lg: '115px'},
            py: {base: '40px', lg: '80px'},
            // width: {base: '100%', lg: '40%'},
            fontSize: '24px',
            fontFamily: `"Roboto" , sans-serif`,
            fontWeight: '300',
            lineHeight: '28.13px',
            color: 'black',
            gap: {base: '31px', lg: '43px'},
            alignItems: 'flex-start',
            alignSelf: 'center'
        },
        imageContainer: {
            width: {base: '100%', lg: '100%'},
            overflow: 'hidden'
        },

        h6: {
            fontSize: '24px',
            marginBottom: {base: '8px', lg: '16px'},
            marginTop: {base: '40px', lg: '0'},
            lineHeight: '28px',
            color: 'black',
            fontWeight: '300',
            fontFamily: `"Roboto" , sans-serif`
        },
        heading: {
            fontSize: {base: '32px', lg: '56px'},
            lineHeight: {base: '32px', lg: '80px'},
            fontFamily: 'Raleway',
            fontWeight: '300'
        },
        link: {
            backgroundColor: 'transparent',
            fontWeight: '300',
            fontFamily: `"Roboto" , sans-serif`,
            fontSize: 'md',
            textDecoration: 'underline',
            color: 'black',
            paddingTop: {base: '8px', lg: '6px'},
            paddingBottom: {base: '8px', lg: '6px'},
            display: {base: 'block', lg: 'inline-block'},
            minWidth: {base: '152px', lg: 'unset'},
            width: {base: '100%', lg: 'fit-content'},
            _hover: {
                textDecoration: 'none',
                backgroundColor: 'transparent'
            },
            _focus: {
                backgroundColor: 'transparent'
            }
        },
        text: {
            color: 'black',
            fontWeight: '300',
            fontFamily: `"Roboto" , sans-serif`,
            fontSize: '16px',
            lineHeight: '24px'
        },
        textMargin: {
            marginBottom: {base: '32px', lg: '39px'}
        }
    }
}
