import {gql} from 'graphql-request'
import {LINK_FRAGMENT, MEDIA_FRAGMENT, THEME_FRAGMENT} from './fragments'

export const getRealmBlogPageQuery = gql`
    ${THEME_FRAGMENT}
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    query PageCollection(
        $locale: String!
        $slug: String! = "article-landing-page"
    ) {
        pageCollection(where: {slug: $slug}, limit: 1, locale: $locale) {
            items {
                sectionsCollection(limit: 5) {
                    items {
                        ... on Carousel {
                            heading
                            headingType
                            description
                            cta {
                                ...LinkFragment
                            }
                            theme {
                                ...ThemeFragment
                            }
                            itemsCollection(limit: 10) {
                                items {
                                    ... on HeroSlide {
                                        heading
                                        cta {
                                            ...LinkFragment
                                        }
                                        image {
                                            ...MediaFragment
                                        }
                                    }
                                }
                            }
                        }
                        ... on ContentGrid {
                            banner {
                                ... on CtaBanner {
                                    title
                                    cta {
                                        ...LinkFragment
                                    }
                                    banner {
                                        ...MediaFragment
                                    }
                                }
                            }
                            columns {
                                ... on ResponsiveValues {
                                    base
                                    sm
                                    md
                                    lg
                                    xl
                                }
                            }
                            heading
                            headingType
                            subTitle
                            cta {
                                ...LinkFragment
                            }
                            itemsCollection(limit: 12) {
                                items {
                                    ... on Article {
                                        cardTitle
                                        cardCta {
                                            ...LinkFragment
                                        }
                                        articleCategoriesCollection(limit: 1) {
                                            items {
                                                ... on ArticleCategory {
                                                    name
                                                }
                                            }
                                        }
                                        slug
                                        sectionsCollection {
                                            items {
                                                ... on List {
                                                    nonReferenceItems
                                                }
                                            }
                                        }
                                        cardImage {
                                            ...MediaFragment
                                        }
                                        cardDescription
                                    }
                                    ... on Card {
                                        title
                                        subtitle
                                        cta {
                                            ...LinkFragment
                                        }
                                        media {
                                            ...MediaFragment
                                        }
                                        description
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
