export const styleConfigChakraKeys = {
    accordion: 'Accordion',
    alert: 'Alert',
    badge: 'Badge',
    button: 'Button',
    checkbox: 'Checkbox',
    container: 'Container',
    drawer: 'Drawer',
    formLabel: 'FormLabel',
    icon: 'Icon',
    input: 'Input',
    modal: 'Modal',
    popover: 'Popover',
    radio: 'Radio',
    select: 'Select',
    skeleton: 'Skeleton',
    tooltip: 'Tooltip',
    app: 'App',
    breadcrumb: 'Breadcrumb',
    header: 'Header',
    search: 'Search',
    overlay: 'Overlay',
    footer: 'Footer',
    footerAccordion: 'FooterAccordion',
    checkoutFooter: 'CheckoutFooter',
    linksList: 'LinksList',
    megaLink: 'MegaLink',
    listMenu: 'ListMenu',
    drawerMenu: 'DrawerMenu',
    nestedAccordion: 'NestedAccordion',
    localeSelector: 'LocaleSelector',
    offlineBanner: 'OfflineBanner',
    socialIcons: 'SocialIcons',
    pagination: 'Pagination',
    productTile: 'ProductTile',
    swatchGroup: 'SwatchGroup',
    imageGallery: 'ImageGallery',
    productScrollerTile: 'ProductScrollerTile',
    productScroller: 'ProductScroller',
    homeServiceCard: 'HomeServiceCard',
    homeServiceSection: 'HomeServiceSection',
    addToCartButton: 'AddToCartButton',
    bookingSection: 'BookingSection',
    bookingCard: 'BookingCard',
    buttonType: 'ButtonType',
    linkType: 'LinkType',
    ProductPricing: 'ProductPricing',
    productBadge: 'ProductBadge',
    videoSection: 'VideoSection',
    homepageBlogsSection: 'HomepageBlogsSection',
    heroSection: 'HeroSection',
    categoryCard: 'CategoryCard',
    newsletterModal: 'NewsletterModal',
    blogCard: 'BlogCard',
    searchProductTile: 'SearchProductTile',
    staticTemplatePage: 'StaticTemplatePage',
    sliderProgress: 'SliderProgress',
    account: 'Account',
    banner: 'Banner',
    blogDetailRichText: 'BlogDetailRichText',
    contactPage: 'ContactPage',
    locationPage: 'LocationPage',
    eventsContentGrid: 'EventsContentGrid'
}
