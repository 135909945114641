import {
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Stack,
    useBreakpointValue,
    useDisclosure
} from '@salesforce/retail-react-app/app/components/shared/ui'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import CartSecondaryButtonGroup from '../../pages/cart/partials/cart-secondary-button-group'
import CartSkeleton from '../../pages/cart/partials/cart-skeleton'
import EmptyCart from '../../pages/cart/partials/empty-cart'

// Hooks
import {useStyledToast} from '../../hooks/use-styled-toast.js'

// Constants
import {
    API_ERROR_MESSAGE,
    TOAST_MESSAGE_REMOVED_ITEM_FROM_CART
} from '@salesforce/retail-react-app/app/constants'

// Utilities
import {
    useProducts,
    useShippingMethodsForShipment,
    useShopperBasketsMutation
} from '@salesforce/commerce-sdk-react'
import {useCurrentBasket} from '@salesforce/retail-react-app/app/hooks/use-current-basket'
import {useCurrentCustomer} from '@salesforce/retail-react-app/app/hooks/use-current-customer'
import debounce from 'lodash/debounce'
import {colors} from '../../constants'
import {REMOVE_CART_ITEM_CONFIRMATION_DIALOG_CONFIG} from '../../pages/cart/partials/cart-secondary-button-group'
import ConfirmationModal from '../confirmation-modal'
import CartDrawerOrderSummary from './partials/cart-drawer-order-summary'
import CartDrawerProductCard from './partials/cart-drawer-product-card'
import DrawerContainer from './partials/drawer-container'

const DEBOUNCE_WAIT = 750

const CartDrawer = ({isDrawerOpen, onDrawerClose}) => {
    // const drawerContainerRef = React.useRef()
    const direction = useBreakpointValue({base: 'bottom', lg: 'right'})

    const {data: basket, isLoading} = useCurrentBasket()

    const productIds =
        basket?.productItems?.map(({productId}) => productId).join(',') ?? ''
    const {data: products} = useProducts(
        {
            parameters: {
                ids: productIds,
                allImages: true
            }
        },
        {
            enabled: Boolean(productIds),
            select: (result) => {
                // Convert array into key/value object with key is the product id
                return result?.data?.reduce((result, item) => {
                    const key = item.id
                    result[key] = item
                    return result
                }, {})
            }
        }
    )
    const {data: customer} = useCurrentCustomer()
    const {isRegistered} = customer

    /*****************Basket Mutation************************/
    const updateItemInBasketMutation =
        useShopperBasketsMutation('updateItemInBasket')
    const removeItemFromBasketMutation = useShopperBasketsMutation(
        'removeItemFromBasket'
    )
    const updateShippingMethodForShipmentsMutation = useShopperBasketsMutation(
        'updateShippingMethodForShipment'
    )
    /*****************Basket Mutation************************/

    const [selectedItem, setSelectedItem] = useState(undefined)
    const [localQuantity, setLocalQuantity] = useState({})
    const [isCartItemLoading, setCartItemLoading] = useState(false)

    const {formatMessage} = useIntl()
    const {showToast} = useStyledToast()
    const modalProps = useDisclosure()

    /******************* Shipping Methods for basket shipment *******************/
    // do this action only if the basket shipping method is not defined
    // we need to fetch the shippment methods to get the default value before we can add it to the basket
    useShippingMethodsForShipment(
        {
            parameters: {
                basketId: basket?.basketId,
                shipmentId: 'me'
            }
        },
        {
            // only fetch if basket is has no shipping method in the first shipment
            enabled:
                !!basket?.basketId &&
                basket.shipments.length > 0 &&
                !basket.shipments[0].shippingMethod,
            onSuccess: (shippingMethods) => {
                const shippingMethodId =
                    _.find(
                        shippingMethods,
                        (shippingMethod) =>
                            shippingMethods?.defaultShippingMethodId ===
                            shippingMethod.id
                    )?.id ||
                    _.find(
                        shippingMethods,
                        (shippingMethod) => shippingMethod.c_storePickupEnabled
                    )?.id ||
                    _.head(shippingMethods?.applicableShippingMethods)?.id
                updateShippingMethodForShipmentsMutation.mutate({
                    parameters: {
                        basketId: basket?.basketId,
                        shipmentId: 'me'
                    },
                    body: {
                        id: shippingMethodId
                    }
                })
            }
        }
    )

    /************************* Error handling ***********************/
    const showError = () => {
        showToast({
            message: formatMessage(API_ERROR_MESSAGE),
            status: 'error'
        })
    }
    /************************* Error handling ***********************/

    /***************************** Update quantity **************************/
    const changeItemQuantity = debounce(async (quantity, product) => {
        // This local state allows the dropdown to show the desired quantity
        // while the API call to update it is happening.
        const previousQuantity = localQuantity[product.itemId]
        setLocalQuantity({...localQuantity, [product.itemId]: quantity})
        setCartItemLoading(true)
        setSelectedItem(product)

        await updateItemInBasketMutation.mutateAsync(
            {
                parameters: {
                    basketId: basket?.basketId,
                    itemId: product.itemId
                },
                body: {
                    productId: product.id,
                    quantity: parseInt(quantity)
                }
            },
            {
                onSettled: () => {
                    // reset the state
                    setCartItemLoading(false)
                    setSelectedItem(undefined)
                },
                onSuccess: () => {
                    setLocalQuantity({
                        ...localQuantity,
                        [product.itemId]: undefined
                    })
                },
                onError: () => {
                    // reset the quantity to the previous value
                    setLocalQuantity({
                        ...localQuantity,
                        [product.itemId]: previousQuantity
                    })
                    showError()
                }
            }
        )
    }, DEBOUNCE_WAIT)

    const handleChangeItemQuantity = async (product, value) => {
        const {stockLevel} = products[product.productId].inventory

        // Handle removing of the items when 0 is selected.
        if (value === 0) {
            // Flush last call to keep ui in sync with data.
            changeItemQuantity.flush()

            // Set the selected item to the current product to the modal acts on it.
            setSelectedItem(product)

            // Show the modal.
            modalProps.onOpen()

            // Return false as 0 isn't valid section.
            return false
        }

        // Cancel any pending handlers.
        changeItemQuantity.cancel()

        // Allow use to selected values above the inventory.
        if (value > stockLevel || value === product.quantity) {
            return true
        }

        // Take action.
        changeItemQuantity(value, product)

        return true
    }
    /***************************** Update quantity **************************/

    /***************************** Remove Item from basket **************************/
    const handleRemoveItem = async (product) => {
        setSelectedItem(product)
        setCartItemLoading(true)
        await removeItemFromBasketMutation.mutateAsync(
            {
                parameters: {basketId: basket.basketId, itemId: product.itemId}
            },
            {
                onSettled: () => {
                    // reset the state
                    setCartItemLoading(false)
                    setSelectedItem(undefined)
                },
                onSuccess: () => {
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                        event: 'removeFromCart',
                        ecommerce: {
                            remove: {
                                products: [
                                    {
                                        id: product.id,
                                        name: product.name,
                                        price: product.price,
                                        brand: product.brand,
                                        category: product.category,
                                        variant: product.variant,
                                        quantity: 1
                                    }
                                ]
                            }
                        }
                    })
                    showToast({
                        message: formatMessage(
                            TOAST_MESSAGE_REMOVED_ITEM_FROM_CART,
                            {quantity: 1}
                        ),
                        status: 'success',
                        isClosable: true
                    })
                },
                onError: () => {
                    showError()
                }
            }
        )
    }

    /********* Rendering  UI **********/
    return (
        <DrawerContainer
            isDrawerOpen={isDrawerOpen}
            onDrawerClose={onDrawerClose}
            direction={direction}
        >
            <>
                <DrawerOverlay zIndex={1500} />
                {isLoading} ? (
                <DrawerContent>
                    <CartSkeleton />
                </DrawerContent>
                ) :
                {basket?.productItems?.length > 0 ? (
                    <DrawerContent
                        width="100%"
                        paddingX={{base: '24px', lg: '48px'}}
                        paddingTop="48px"
                        paddingBottom={{base: '24px', lg: '48px'}}
                        containerProps={{
                            zIndex: 1500
                        }}
                    >
                        <DrawerCloseButton
                            top="48px"
                            right={{base: '24px', lg: '48px'}}
                            fontSize="12px"
                            height="24px"
                            width="24px"
                        />
                        <DrawerHeader
                            boxShadow="none"
                            fontFamily="Raleway"
                            fontWeight="400"
                            fontSize={{base: '18px', lg: '24px'}}
                            p={0}
                            px="0"
                            paddingRight="0"
                            paddingLeft="0"
                        >
                            Your Cart
                        </DrawerHeader>

                        <DrawerBody
                            padding={0}
                            display="flex"
                            flexDirection="column"
                            gap={{base: '18px', lg: '36px'}}
                            overflow="hidden"
                        >
                            <Stack
                                height="100%"
                                maxHeight="calc(100vh - 232px)"
                                overflowY="auto"
                                sx={{
                                    scrollSnapType: 'y mandatory',
                                    WebkitOverflowScrolling: 'touch', // Safari touch scrolling needed for scroll snap
                                    '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
                                    'scrollbar-width': 'none', // Hide scrollbar for Firefox
                                    '&::-webkit-scrollbar': {
                                        display: 'none' // Hide scrollbar for Chrome, Safari, and Opera
                                    }
                                }}
                            >
                                {basket?.productItems?.map(
                                    (productItem, idx) => {
                                        return (
                                            <CartDrawerProductCard
                                                key={productItem.productId}
                                                index={idx}
                                                secondaryActions={
                                                    <CartSecondaryButtonGroup
                                                        onRemoveItemClick={(
                                                            removedProduct
                                                        ) =>
                                                            handleRemoveItem(
                                                                removedProduct ||
                                                                    productItem
                                                            )
                                                        }
                                                        cartDrawer="true"
                                                    />
                                                }
                                                product={{
                                                    ...productItem,
                                                    ...(products &&
                                                        products[
                                                            productItem
                                                                .productId
                                                        ]),
                                                    price: productItem.price,
                                                    quantity: localQuantity[
                                                        productItem.itemId
                                                    ]
                                                        ? localQuantity[
                                                              productItem.itemId
                                                          ]
                                                        : productItem.quantity
                                                }}
                                                onItemQuantityChange={handleChangeItemQuantity.bind(
                                                    this,
                                                    productItem
                                                )}
                                                showLoading={
                                                    isCartItemLoading &&
                                                    selectedItem?.itemId ===
                                                        productItem.itemId
                                                }
                                                handleRemoveItem={
                                                    handleRemoveItem
                                                }
                                            />
                                        )
                                    }
                                )}
                            </Stack>

                            <CartDrawerOrderSummary
                                basket={basket}
                                border="none"
                                onClose={onDrawerClose}
                            />

                            <ConfirmationModal
                                {...REMOVE_CART_ITEM_CONFIRMATION_DIALOG_CONFIG}
                                onPrimaryAction={() => {
                                    handleRemoveItem(selectedItem)
                                }}
                                onAlternateAction={() => {}}
                                {...modalProps}
                            />
                        </DrawerBody>
                    </DrawerContent>
                ) : (
                    <DrawerContent
                        width="100%"
                        containerProps={{zIndex: '1501'}}
                        bgGradient={colors.blueBgGradient}
                    >
                        <DrawerCloseButton
                            fontSize="12px"
                            height="24px"
                            width="24px"
                        />
                        <DrawerBody overflow="hidden">
                            <EmptyCart
                                isRegistered={isRegistered}
                                width="90%"
                                onClose={onDrawerClose}
                                bgColor="transparent"
                            />
                        </DrawerBody>
                    </DrawerContent>
                )}
            </>
        </DrawerContainer>
    )
}

CartDrawer.propTypes = {
    isDrawerOpen: PropTypes.bool,
    onDrawerClose: PropTypes.func
}

export default CartDrawer
