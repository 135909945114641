import React, {useEffect} from 'react'

const GoogleAnalyticsScript = () => {
    useEffect(() => {
        const onClient = typeof window !== 'undefined'

        if (onClient) {
            const script = document.createElement('script')
            script.src =
                'https://www.googletagmanager.com/gtag/js?id=G-3RFZ17WZ6K'
            script.async = true

            // Inject the script into the DOM
            document.head.appendChild(script)

            // Define and initialize gtag after the script has loaded
            script.onload = () => {
                window.dataLayer = window.dataLayer || []
                window.gtag = function () {
                    window.dataLayer.push(arguments)
                }
                window.gtag('js', new Date())
                window.gtag('config', 'G-3RFZ17WZ6K')

                // Example of tracking a page view
                window.gtag('event', 'page_view', {
                    page_title: document.title,
                    page_location: window.location.href,
                    page_path: window.location.pathname
                })
            }

            // Optionally handle script errors
            script.onerror = () => {
                console.error('Error loading Google Analytics script')
            }
        }
    }, [])

    // Empty dependency array to run this effect only once on component mount
    return null // No UI component to render
}

export default GoogleAnalyticsScript
