import {
    Input,
    Button,
    InputGroup,
    useMultiStyleConfig,
    Box,
    Heading,
    FormLabel
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import React, {useRef, useCallback, useState, useEffect} from 'react'
import {colors, styleConfigChakraKeys} from '../../constants'
import Link from '../link'
import {LogoSmallBlackIcon} from '../../pages/about/components/CustomIcons'
import {CheckIcon} from '../icons'
import {useIntl} from 'react-intl'

const PasswordProtect = ({children}) => {
    const {rennaiRequirePass, rennaiEnvPassword} = getConfig()

    const defPassword = rennaiEnvPassword ?? null
    const [isAuthorized, setIsAuthorized] = useState(true)
    const passwordInputRef = useRef()
    const [error, setError] = useState('')
    const styles = useMultiStyleConfig(styleConfigChakraKeys.input)
    const {formatMessage} = useIntl()
    const onClickLink = useCallback(() => {
        if (typeof window === 'undefined') return

        const protocol = window?.location?.protocol ?? 'https:'
        const url = (window?.location?.host ?? 'www.rennai.com')
            ?.split('.')
            .slice(1)
            .join('.')

        window.location.href = `${protocol}//${url}`
    }, [])

    useEffect(() => {
        const authorizedDevMode =
            localStorage.getItem('authorized-dev-mode') ?? false
        if (!authorizedDevMode && rennaiRequirePass) setIsAuthorized(false)
    }, [])

    const handleSubmit = useCallback((e) => {
        e.preventDefault()
        const password = e?.target?.elements['password']
        if (password?.value === defPassword) {
            setIsAuthorized(true)
            localStorage.setItem('authorized-dev-mode', true)
            setError('')
        } else {
            setError(
                formatMessage({
                    id: 'rennai.require_pass_error',
                    defaultMessage:
                        'The password you entered is incorrect. Please try again.'
                })
            )
        }
    }, [])

    return (
        <>
            {isAuthorized && <>{children}</>}
            {!isAuthorized && (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    gap={10}
                >
                    <div className="Header__logo--wrapper">
                        <Link className="Header__logo" href="/">
                            <LogoSmallBlackIcon />
                        </Link>
                    </div>
                    <Heading as="h1" className="h1">
                        {formatMessage({
                            id: 'rennai.restricted_access',
                            defaultMessage: 'Restricted Access'
                        })}
                    </Heading>
                    <form onSubmit={handleSubmit}>
                        <FormLabel
                            fontFamily="Roboto"
                            fontWeight="300"
                            fontSize="14px"
                            color={colors.paleYellow}
                        >
                            {formatMessage({
                                id: 'password_card.label.password',
                                defaultMessage: 'Password'
                            })}
                        </FormLabel>
                        <InputGroup {...styles.inputGroup}>
                            <Input
                                autoComplete="off"
                                id="password"
                                type="input"
                                ref={passwordInputRef}
                                variant="filled"
                            />
                            <Button
                                title="Check the pass"
                                type="submit"
                                width="fit-content"
                                minWidth="50px"
                                textAlign="center"
                                margin="0px 4px"
                                borderRadius="25px"
                                display="flex"
                                gap="10px"
                                padding="0 15px"
                                justifyContent="left"
                                fontSize="14px"
                                fontWeight="300"
                                background={colors.black}
                                backgroundColor={colors.black}
                                _hover={{
                                    background: colors.black,
                                    backgroundColor: colors.black
                                }}
                                _active={{
                                    background: colors.black,
                                    backgroundColor: colors.black
                                }}
                                _disabled={{
                                    background: colors.black,
                                    backgroundColor: colors.black
                                }}
                            >
                                <CheckIcon
                                    color="white"
                                    right="-40px"
                                    top="1px"
                                />
                            </Button>
                        </InputGroup>
                        {error && <p style={{color: 'red'}}>{error}</p>}
                        <Link
                            buttonStyles={{
                                margin: '100px 0',
                                width: '100%',
                                color: 'white',
                                fontSize: '16px',
                                fontWeight: '400',
                                backgroundColor: colors.cloudy,
                                background: colors.cloudy,
                                borderRadius: '33px',
                                padding: '15px 30px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                _hover: {
                                    backgroundColor: colors.cloudy,
                                    textDecoration: 'none'
                                }
                            }}
                            text={formatMessage({
                                id: 'rennai.go_to_main_page',
                                defaultMessage: 'Go to the main page'
                            })}
                            type={'primary'}
                            onLinkClick={onClickLink}
                        />
                    </form>
                </Box>
            )}
        </>
    )
}

export default PasswordProtect
